import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_ACCOUNT_CREDITS } from "operations/Transaction";
import Card, { CardTitle } from "../../components/Card";
import Format from "components/Format";
import { DateTime } from "luxon";

import "./credits.scss";

const DUE = 6;

export const WEEKS = [
	{
		startDate: "2025-07-02",
		endDate: "2025-07-04",
	},
	{
		startDate: "2025-07-06",
		endDate: "2025-07-11",
	},
	{
		startDate: "2025-07-13",
		endDate: "2025-07-18",
	},
	{
		startDate: "2025-07-20",
		endDate: "2025-07-25",
	},
	{
		startDate: "2025-07-27",
		endDate: "2025-08-01",
	},
	{
		startDate: "2025-08-04",
		endDate: "2025-08-08",
	},
	{
		startDate: "2025-08-10",
		endDate: "2025-08-15",
	},
	{
		startDate: "2025-08-18",
		endDate: "2025-08-22",
	},
	{
		startDate: "2025-08-25",
		endDate: "2025-08-29",
	},
];

export default function Refunds() {
	const [week, setWeek] = useState(0);

	const { error, loading, data } = useQuery(GET_ACCOUNT_CREDITS, {
		variables: WEEKS[week],
		fetchPolicy: "cache-and-network",
	});

	const [totals, setTotals] = useState({
		totalAccounts: 0,
		creditsAppliedCount: 0,
		totalAmountDue: 0,
		infoPacksToSend: 0,
		// covidFormsToSend: 0,
		// incompleteCovidForms: 0,
	});

	useEffect(() => {
		if (data && data.accountCredits) {
			console.log(data);
			const totalAccounts = data.accountCredits.length;
			const creditsAppliedCount = data.accountCredits.reduce(
				(total, current) =>
					(total = current.creditsApplied ? total + 1 : total),
				0,
			);
			const totalAmountDue = data.accountCredits.reduce(
				(total, current) => (total += current.amountDueWeek),
				0,
			);

			const infoPacksToSend = data.accountCredits.reduce(
				(total, current) =>
					(total += current.paymentRemindersSent === 0 ? 1 : 0),
				0,
			);
			// const covidFormsToSend = data.accountCredits.reduce(
			//   (total, current) => (total += current.covidFormsSent === 0 ? 1 : 0),
			//   0
			// );
			// const incompleteCovidForms = data.accountCredits.reduce(
			//   (total, current) => (total += current.covidFormCompleted ? 0 : 1),
			//   0
			// );

			setTotals({
				totalAccounts,
				creditsAppliedCount,
				totalAmountDue,
				infoPacksToSend,
				// covidFormsToSend,
				// incompleteCovidForms,
			});
		}
	}, [data]);

	if (error) return "Error loading data. Please refresh the page.";
	if (loading) return "Loading...";

	const {
		totalAccounts,
		creditsAppliedCount,
		totalAmountDue,
		infoPacksToSend,
		// covidFormsToSend,
		// incompleteCovidForms,
	} = totals;

	return (
		<>
			<h1>Apply credits</h1>
			<div className="weekContainer">
				{WEEKS.map((weekData, i) => (
					<button
						key={i}
						type="button"
						className={`weekButton ${week === i ? "selected" : ""}`}
						onClick={() => setWeek(i)}
					>
						{DateTime.fromISO(weekData.startDate).toLocaleString(
							DateTime.DATE_FULL,
						)}
					</button>
				))}
			</div>
			<div className="weekCountContainer">
				<div
					className={`${
						creditsAppliedCount < totalAccounts ? "needsCreditsApplied" : ""
					}`}
				>
					<strong>Credits applied:</strong> {creditsAppliedCount}/
					{totalAccounts}
				</div>
				<div>
					<strong>Payment date:</strong>{" "}
					{DateTime.fromISO(WEEKS[week].startDate)
						.minus({ weeks: DUE })
						.toLocaleString(DateTime.DATE_FULL)}
				</div>
				<div>
					<strong>Total due:</strong>{" "}
					<Format type="currency" value={totalAmountDue} />
				</div>
				<div>
					<strong>Info packs to send:</strong> {infoPacksToSend}
				</div>
				{/* <div>
          <strong>Covid forms to send:</strong> {covidFormsToSend}
        </div>
        <div>
          <strong>Incomplete covid forms:</strong> {incompleteCovidForms}
        </div> */}
			</div>
			{data &&
				data.accountCredits
					.sort((a, b) => {
						if (a.name > b.name) return 1;
						if (b.name > a.name) return -1;
						return 0;
					})
					.map((account) => {
						return (
							<Card
								key={account.id}
								to={`/account/${account.id}`}
								style={{
									display: "grid",
									gridTemplateColumns:
										"200px 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr .5fr",
								}}
							>
								<CardTitle title="Account">{account.name}</CardTitle>
								<CardTitle title="Account balance">
									<Format
										type="currency"
										value={account.accountBalance}
										color
									/>
								</CardTitle>
								<CardTitle title="Amount due">
									<Format type="currency" value={account.amountDueWeek} color />
								</CardTitle>
								<CardTitle title="Credits applied?">
									{account.creditsApplied ? (
										<div style={{ color: "green" }}>TRUE</div>
									) : (
										<div style={{ color: "red" }}>FALSE</div>
									)}
								</CardTitle>
								<CardTitle title="Email(s) sent" style={{ fontSize: "1.2rem" }}>
									<strong>Info pack/payment reminder:</strong>{" "}
									{account.paymentRemindersSent}
									{/* <br />
                  <strong>Covid form:</strong> {account.covidFormsSent} */}
								</CardTitle>
								{/* <CardTitle title="Screening form completed">
                  {account.covidFormCompleted ? (
                    <div style={{ color: "green" }}>TRUE</div>
                  ) : (
                    <div style={{ color: "red" }}>FALSE</div>
                  )}
                </CardTitle> */}
								<CardTitle title="Credit card set">
									{account.stripeCustomerId ? (
										<div style={{ color: "green" }}>TRUE</div>
									) : (
										<div style={{ color: "red" }}>FALSE</div>
									)}
								</CardTitle>
								<CardTitle title="Weekly snackbar amount">
									{account.snackbarAmountLimit ? (
										<Format
											type="currency"
											value={account.snackbarAmountLimit / 100}
										/>
									) : (
										"Not set"
									)}
								</CardTitle>
								<CardTitle title="Phone">
									{account.mobilePhone
										? account.mobilePhone
												.replace(/[^0-9]/g, "")
												.match(/^(\d{3})(\d{3})(\d{4})$/)
												.slice(1, 4)
												.reduce((num, part) => (num += "-" + part))
										: ""}
								</CardTitle>
								<CardTitle title="Payment link">
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`${
											process.env.NODE_ENV === "development"
												? "http://teenranch.lvh.me:3000"
												: "https://teenranch.encampment.app"
										}/b/${account.testToken}/preview`}
										onClick={(e) => e.stopPropagation()}
									>
										Preview
									</a>{" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`${
											process.env.NODE_ENV === "development"
												? "http://teenranch.lvh.me:3000"
												: "https://teenranch.encampment.app"
										}/b/${account.testToken}/snackbar`}
										onClick={(e) => e.stopPropagation()}
									>
										Snackbar
									</a>
								</CardTitle>
							</Card>
						);
					})}
		</>
	);
}
